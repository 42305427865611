<div class="container-fluid" [ngStyle]="{'background' : 'url(./assets/background.png) no-repeat center center fixed', 'background-size': 'cover'}">
    <div class="container py-3 body" [ngClass]="{'fixed-overlay': showForm}">
      <form [formGroup]="formGroup" (ngSubmit)="onSubmit()" class="form-container">
        <img src="./assets/header.PNG" alt="" class="img-fluid rounded mx-auto d-block" srcset="">
        <div class="mb-3">
          <label class="text-center form-label fw-bold py-3 mx-auto d-block fs-2">Registration Form 2024</label>
          <div class="px-2 py-3">
            <div class="float-lg-start">
              <input type="radio" formControlName="actionType" value="Register" class="radio-large" /> 
              <span>Register</span>
            </div>
            <div class="float-lg-end">
              <input type="radio" formControlName="actionType" value="Acknowledgement" class="radio-large" /> 
              <span>Get Acknowledgement</span>
            </div>
            <div class="clearfix"></div>
          </div>
        </div>
  
        <!-- Mobile Number (Visible only for Get Acknowledgement) -->
        <div *ngIf="formGroup.get('actionType').value === 'Acknowledgement'" class="mb-3">
          <label for="mobileNumber" class="form-label">Mobile Number *</label>
          <input type="tel" id="mobileNumber" formControlName="mobileNumber" class="form-control" placeholder="Mobile Number" required />
        </div>
  
        <!-- Registration form (Visible only for Register) -->
        <div *ngIf="formGroup.get('actionType').value === 'Register'">
          <div class="mb-3">
            <label for="fullName" class="form-label">Full Name *</label>
            <input type="text" id="fullName" formControlName="fullName" class="form-control" placeholder="Name" required />
          </div>
          <div class="mb-3">
            <label for="email" class="form-label">Email *</label>
            <input type="email" id="email" formControlName="email" class="form-control" placeholder="Email" required />
          </div>
          <div class="mb-3">
            <label for="whatsappNumber" class="form-label">WhatsApp Number *</label>
            <input type="tel" id="whatsappNumber" formControlName="whatsappNumber" class="form-control" placeholder="WhatsApp Number" required />
          </div>
          <div class="mb-3">
            <label for="address" class="form-label">Address *</label>
            <textarea id="address" formControlName="address" class="form-control" placeholder="Address" required></textarea>
          </div>
          <div class="mb-3">
            <label for="gender" class="form-label">Gender *</label>
            <select id="gender" formControlName="gender" class="form-select" required>
              <option value="" disabled selected>Select Gender</option>
              <option value="Male">Male</option>
              <option value="Female">Female</option>
              <option value="Will Not Disclose">Will Not Disclose</option>
            </select>
          </div>
          <div class="mb-3">
            <label for="age" class="form-label">Age *</label>
            <select id="age" formControlName="age" class="form-select" required>
                <option value="" disabled selected>- Select Age -</option>
                <option value="Below 18"> 14 to 20</option>
                <option value="21 to 25">21 to 25</option>
                <option value="26 to 30">26 to 30</option>
                <option value="31 to 35">31 to 35</option>
                <option value="36 to 40">36 to 40</option>
                <option value="Above 40">Above 40</option>
                <option value="Will Not Disclose">Will Not Disclose</option>
            </select>
          </div>
          <div class="mb-3">
            <label for="tshirtSize" class="form-label">T-shirt Size *</label>
            <select id="tshirtSize" formControlName="tshirtSize" class="form-select" required>
              <option value="" disabled selected>Select T-shirt Size</option>
              <option value="S">S</option>
              <option value="M">M</option>
              <option value="L">L</option>
              <option value="XL">XL</option>
              <option value="XXL">XXL</option>
              <option value="XXXL">XXXL</option>
            </select>
          </div>
          <div class="mb-3">
            <label for="exploreType" class="form-label">Explore Type *</label>
            <select id="exploreType" formControlName="exploreType" class="form-select" required>
              <option value="" disabled selected>-Select Explore Type-</option>
              <option value="Friend’s Referral">Friend’s Referral</option>
              <option value="FM">FM</option>
              <option value="Notice">Notice</option>
              <option value="Flex Board">Flex Board</option>
              <option value="Auto or Bus">Auto or Bus</option>
              <option value="Social Media">Social Media</option>
            </select>
          </div>
          <div class="mb-3">
            <label for="terms" class="form-label">Terms & Conditions:</label>
            <div class="terms-conditions alert alert-warning">
              <ul>
                <li>Kindly bring a xerox copy of Adhaar card on Marathon day.</li>
                <li>Follow all the rules created by RC DHARMAPURI ELITE.</li>
                <li>RC DHARMAPURI ELITE has all rights reserved.</li>
                <li>If you engage in any form of cheating, the team can disqualify you.</li>
                <li>If you misbehave with the organizing team, the organizer has the full right to disqualify a participant.</li>
                <li>The paid amount will not be refunded.</li>
              </ul>
            </div>
          </div>
          <div class="mb-3">
            <label for="paymentSection" class="form-label">Payment and Submit</label>
            <div class="alert alert-info">
              <p class="fw-bold">
                Please pay the event organizer and send the payment screenshot via WhatsApp to 9842012345. <br />
                ENTRY FEE: ₹99
              </p>
            </div>
          </div>
          <div class="form-actions">
            <button type="submit" class="btn btn-primary w-100" [disabled]="formGroup.invalid">Pay</button>
            <button type="button" class="btn btn-secondary w-100 mt-2" (click)="closeForm()">Cancel</button>
          </div>
        </div>
        <div class="event-info">
            <h5 class="py-4 text-break">தர்மபுரி எலைட் ரோட்டரி சங்கம் மற்றும் கிருஷ்ணா சில்க்ஸ் - விழிப்புணர்வு மாரத்தான்</h5>
            <p>அக்டோபர் மாதம் 16ஆம் தேதி (20.10.2024, ஞாயிற்றுக்கிழமை) நடக்கவிருக்கும் மிகப்பிரமாண்டமான விழிப்புணர்வு (Say NO to Drugs) மாரத்தானில் கலந்து கொள்ள....</p>
            <p><strong>கட்டணம்:</strong> ₹99 மட்டுமே</p>
            <p>பங்கேற்கும் அனைவருக்கும் டி-ஷர்ட், சான்றிதழ், ஸ்னாக்ஸ் வழங்கப்படும், மேலும் சிறப்பிடம் பெறுபவர்களுக்கு மெடல் மற்றும் பண விருது வழங்கப்படும்.</p>
            <div class="prizes">
                <p><strong>1st Place:</strong> Rs. 5,000 + 🏆 + 🥇</p>
                <p><strong>2nd Place:</strong> Rs. 3,000 + 🏆 + 🥈</p>
                <p><strong>3rd Place:</strong> Rs. 2,000 + 🏆 + 🥉</p>
                <p><strong>4th to 10th Place:</strong> Each Rs. 1,000 + 🎖</p>
                <p><strong>11th to 20th Place:</strong> Each Rs. 500 + 🎖</p>
                <p>🏃🏼‍♂ ஆண்- 20, 🏃‍♀ பெண்- 20 இருபாலருக்கும் தனித்தனியாக பரிசுகள் வழங்கப்படும்.</p>
            </div>
        </div>
        <div>
             <p class="py-3 fw-bold text-center"> Copyright © 2024 Designed by <a class="text-decoration-none text-light" href="https://genuineitsolution.in/"> Genuine IT Solution</a></p>
        </div>
      </form>
    </div>
  </div>
  