
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.css']
})
export class RegistrationComponent implements OnInit {
 
  formGroup: FormGroup;
  showForm: boolean = true;

  constructor(private fb: FormBuilder) { }

  ngOnInit(): void {
    this.formGroup = this.fb.group({
      actionType: ['Register', Validators.required],  // Default selection is 'Register'
      fullName: ['', [Validators.required, Validators.minLength(3)]],
      email: ['', [Validators.required, Validators.email]],
      mobileNumber: ['', [Validators.required, Validators.pattern('[0-9]{10}')]],
      whatsappNumber: ['', [Validators.required, Validators.pattern('[0-9]{10}')]],
      address: ['', Validators.required],
      gender: ['', Validators.required],
      age: ['', Validators.required],
      tshirtSize: ['', Validators.required],
      exploreType: ['', Validators.required]
    });

    this.formGroup.get('actionType').valueChanges.subscribe(value => {
      if (value === 'Acknowledgement') {
        this.formGroup.get('fullName').clearValidators();
        this.formGroup.get('email').clearValidators();
        this.formGroup.get('whatsappNumber').clearValidators();
        this.formGroup.get('address').clearValidators();
        this.formGroup.get('gender').clearValidators();
        this.formGroup.get('age').clearValidators();
        this.formGroup.get('tshirtSize').clearValidators();
        this.formGroup.get('exploreType').clearValidators();
      } else {
        this.setRegisterFormValidators();
      }
      this.formGroup.get('fullName').updateValueAndValidity();
      this.formGroup.get('email').updateValueAndValidity();
      this.formGroup.get('whatsappNumber').updateValueAndValidity();
      this.formGroup.get('address').updateValueAndValidity();
      this.formGroup.get('gender').updateValueAndValidity();
      this.formGroup.get('age').updateValueAndValidity();
      this.formGroup.get('tshirtSize').updateValueAndValidity();
      this.formGroup.get('exploreType').updateValueAndValidity();
    });
  }

  setRegisterFormValidators(): void {
    this.formGroup.get('fullName').setValidators([Validators.required, Validators.minLength(3)]);
    this.formGroup.get('email').setValidators([Validators.required, Validators.email]);
    this.formGroup.get('whatsappNumber').setValidators([Validators.required, Validators.pattern('[0-9]{10}')]);
    this.formGroup.get('address').setValidators(Validators.required);
    this.formGroup.get('gender').setValidators(Validators.required);
    this.formGroup.get('age').setValidators(Validators.required);
    this.formGroup.get('tshirtSize').setValidators(Validators.required);
    this.formGroup.get('exploreType').setValidators(Validators.required);
  }

  onSubmit(): void {
    if (this.formGroup.valid) {
      console.log(this.formGroup.value);
      // You can handle the submission logic here
    } else {
      console.log("Form is not valid");
    }
  }

  closeForm(): void {
    this.showForm = false;
  }
}
